if (("ontouchstart" in document.documentElement)) {
	document.documentElement.className += "touch";
}
$(document).ready(function(){
	
	$.cookieBar();
	
	//nav mobile
	$("button.navbar-toggle").click(function(e){
		$("nav").slideToggle("fast");
		e.preventDefault();
		e.stopPropagation();
	});
	$(window).on("load resize",function(){
		var screenwidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		if (screenwidth <= 767) {
			$("nav").hide();
		}
		if (screenwidth >= 768) {
			$("nav").show();
		}
	});
	
	$( "#datepicker" ).datepicker({
		dateFormat: "dd-mm-yy"
	});
	
});